import React from 'react';
import { Container } from 'react-bootstrap';
import {Helmet} from 'react-helmet';
import './BannerTop.scss';

export default function BannerTop() {
    return ( 
        <>
        <Helmet>
            <title>Air Socks | Calcetines antiderrapantes | calcetines para trampolines</title>
            <meta name='description' content='calcetines con antiderrapante, ideales para usarlos en parques de trampolines, sesiones de yoga, hospitales, parques de diversiones, salones de fiestas infantiles.'  data-react-helmet="true"/>
        </Helmet>
        <Container className='espacio'>
         </Container>
        <Container fluid className='container_banner' id='Home'>
           
            <h1>Calcetines antiderrapantes</h1>
           
        </Container>
        </>
    )
}
