import React from 'react';
import {Container, Row, Col, Carousel} from 'react-bootstrap';
import Logo from '../../assets/png/Logo.png';
import Telefono from '../../assets/png/support.png'
import Email from '../../assets/png/email.png';
import Location from '../../assets/png/location.png';
import FondoAzul1 from '../../assets/png/fondo_azul1.png';
import FondoAzul2 from '../../assets/png/fondo_azul2.png';
import FondoAzul3 from '../../assets/png/fondo_azul3.png';
import {ReactComponent as Facebook} from "../../assets/svg/facebook.svg";
import { Link, animateScroll as scroll } from "react-scroll";
import Fade from 'react-reveal/Fade';
import './Footer.scss';

export default function Footer() {
    return (
        <Container fluid className='zigzag' id='Contacto'>
            <Container className='container__footer'>
            <Fade top>
               <a href="" className='logoRef'>
               <img src={Logo} alt="" className='logo'/>    
               </a>
               </Fade>
            <Row>
            <Col md={2} sm={12} xl={2} className='container__footer__info'  >
            <Fade left>
             <h4>Síguenos</h4>
             <div className="container__footer__info__social">
                <a href="">
                  <Facebook/>  
                </a>
             </div>
             </Fade>
             </Col>  
             <Col md={4} sm={12} xl={4} className='container__footer__info' >
             <Fade bottom>
             <h4>Contacto</h4>
             <div className='container__footer__info__medios'>
             <img src={Telefono} alt="" />
             <p>(52) 5630566498</p>
             </div>
             <div className='container__footer__info__medios'>
             <img src={Email} alt="" />
             <p>informes@airsocks.com.mx</p>
             </div>
             <div className='container__footer__info__medios'>
             <img src={Location} alt="" />
             <p>Calle Liras 127, Aquiles Serdán, 15430 Venustiano Carranza, CDMX, México, 15430 Ciudad de México, México.</p>
             </div>
             </Fade>
             </Col>
             <Col md={3} sm={12} xl={3} className='container__footer__info'>
             <Fade right>
             <h4>Menú</h4>
             <div className="container__footer__info__menu">
                <ul>
                <li className="nav__item"><Link  className="nav__link"
                activeClass="active"
                to="Home"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Home</Link></li>
                <li className="nav__item"><Link  className="nav__link"
                activeClass="active"
                to="Conocenos"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Conocenos</Link></li>
                <li className="nav__item"><Link className="nav__link"
                activeClass="active"
                to="Productos"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Productos</Link></li>
                <li className="nav__item"><Link className="nav__link"
                activeClass="active"
                to="Ubicacion"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Ubicacion</Link></li>
                <li className="nav__item"><Link className="nav__link"
                activeClass="active"
                to="Contacto"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Contacto</Link></li>
             </ul>
             </div>
             </Fade>
             </Col> 
             <Col md={3} sm={12} xl={3} className='container__footer__info'>
             <Carousel fade> 
  <Carousel.Item>
    <img
      className="imageFooter"
      src={FondoAzul1}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="imageFooter"
      src={FondoAzul2}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="imageFooter"
      src={FondoAzul3}
      alt="Third slide"
    />
  </Carousel.Item>
</Carousel>  
             </Col>  
             </Row>
             <Row>
                 <Col md={12} sm={12} xl={12} className='copy'>
                  <p>&copy;  AIR SOCKS</p>  
                 </Col>
             </Row>
            </Container>
        </Container>
    )
}
