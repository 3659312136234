import React from 'react';
import Calceta from '../../assets/png/calcetitas/sockError.png';
import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import './Error404.scss';


export default function Error404() {
    return (
        <div>
            <Container className='espacio404'></Container>
            <Container className='error'>
                <div className='image'>
                  <img src={Calceta} alt="" />
                </div>
                <div className="error404">
                    <h1>Error 404</h1>
                </div>
               <div className="texto">
                 <p>No se encontró esta pagina vuelve a home para estar seguro </p>   
               </div>
               <div className="button">
               <Link to='/'>
                <div className="enlace">
                <p>Volver a Home</p>  
               </div>  
               </Link>
               </div>
               
            </Container>
        </div>
    )
}
