import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import Calceta from '../../assets/png/calcetitas/socks.png';
import Calceta1 from '../../assets/png/calcetitas/socks_amarillo.png';
import Calceta2 from '../../assets/png/calcetitas/socks_azul.png';
import Calceta3 from '../../assets/png/calcetitas/socks_morado.png';
import Calceta4 from '../../assets/png/calcetitas/socks_morado2.png';
import Calceta5 from '../../assets/png/calcetitas/socks_naranja.png';
import Calceta6 from '../../assets/png/calcetitas/socks_nerde2.png';
import Calceta7 from '../../assets/png/calcetitas/socks_rojo.png';
import Calceta8 from '../../assets/png/calcetitas/socks_rosa.png';
import Calceta9 from '../../assets/png/calcetitas/socks_verde.png';
import Fade from 'react-reveal/Fade';
import Logo from '../../assets/png/Logo.png';
import './Conocenos.scss';

export default function Conocenos() {
 
    return (
        <Container className='conocenos' id='Conocenos'>
            <Row>
            
                <Col md={6} sm={12} xl={6} className='texto'>
                <Fade left>
                    <h1>Somos <span>Air Socks</span> </h1>
                    <div className='line'></div>
                <p>Fabricamos calcetines con <span>antiderrapante</span>, ideales para usarlos en parques de trampolines, sesiones de yoga, hospitales, parques de diversiones, salones de fiestas infantiles. </p>

  <br /> <p> Tenemos más de 6 años en el mercado de los calcetines, contando con una amplia gama de productos. Damos servicio por mayoreo y menudeo y a toda la República.</p>
  </Fade>
                </Col>
                <Col md={6} sm={12} xl={6} className='image_logo'>
                    <Fade right>
                    <img src={Logo} alt="" />
                    </Fade>
                </Col>
               
            </Row>
            <div className="bg">
                <ul className="bg-area">
                    <li><img src={Calceta} alt="" /></li>
                    <li><img src={Calceta1} alt="" /></li>
                    <li><img src={Calceta2} alt="" /></li>
                    <li><img src={Calceta3} alt="" /></li>
                    <li><img src={Calceta4} alt="" /></li>
                    <li><img src={Calceta5} alt="" /></li>
                    <li><img src={Calceta6} alt="" /></li>
                    <li><img src={Calceta7} alt="" /></li>
                    <li><img src={Calceta8} alt="" /></li>
                    <li><img src={Calceta9} alt="" /></li>
                </ul>
            </div>
        </Container>
    )
}
