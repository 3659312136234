import React from 'react';
import './Personal.scss';

export default function Personal() {
    return (
        <div>
            <h1>Seccion para visualizar el producto</h1>
        </div>
    )
}
