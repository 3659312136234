import React, {useEffect} from 'react';
import {Container} from 'react-bootstrap';
import Bounce from 'react-reveal/Bounce';
import './Map.scss';

export default function Map() {
    return (
        <Container fluid className='container__map' id='Ubicacion'>
            <Container>
            <Bounce left>
            <p><span>Nos encontramos en:</span> Calle Liras 127, Aquiles Serdán, 15430 Venustiano Carranza, CDMX, México, 15430 Ciudad de México, México.</p>    
            </Bounce>
            </Container>
           <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3762.1560380110777!2d-99.09586798582514!3d19.448838145202632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d1fbe4e7cac139%3A0xb569f01ec22d488c!2sLiras%20127%2C%20Aquiles%20Serd%C3%A1n%2C%20Venustiano%20Carranza%2C%2015430%20Ciudad%20de%20M%C3%A9xico%2C%20CDMX!5e0!3m2!1ses-419!2smx!4v1640739682512!5m2!1ses-419!2smx" width="100%" height="80%" frameborder="0" style={{border:0}} allowfullscreen="" loading="lazy"></iframe>
        </Container>
    )
}
