import React from 'react';
import BannerTop from '../../Components/Banner';
import Conocenos from '../../Components/Conocenos';
import Composicion from '../../Components/Composicion';
import Mapa from '../../Components/Mapa';
import './Principal.scss';

export default function Principal() {
    return (
        <div>
            <BannerTop/>
            <Conocenos/>
            <Composicion/>
            <Mapa/>
        </div>
    )
}
