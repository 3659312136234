import React from 'react';
import {Container, Row, Col, Carousel} from 'react-bootstrap';
import Colors from '../../assets/png/colors.png';
import CalcetaIcon from "../../assets/png/calcetin_antii.png";
import Elastano from '../../assets/png/elastastico.png';
import Calceta2 from '../../assets/png/2.png';
import Calceta3 from '../../assets/png/3.png';
import Calceta4 from '../../assets/png/4.png';
import Calceta5 from '../../assets/png/5.png';
import Fade from 'react-reveal/Fade';
import './Composicion.scss';
/* import 'aos/dist/aos.css' */

export default function Composicion() {
    return (
        <Container fluid className='Composicion' id='Productos'>
        <Container className='relleno'>
            <Row>
            <Col md={4} sm={12} xl={4} className='texto'>
            <Fade left>
                <img src={Colors} alt="" /> 
                <p className='descripcion'>Tenemos 6 colores</p>
                <p className='contenido'>
                los más populares y de moda, con efecto fluorescente, sin embargo, podemos trabajar en tu proyecto y aterrizar el diseño y color a tu elección.
                </p>
                </Fade>
                </Col>
                <Col md={4} sm={12} xl={4} className='texto'>
                <Fade top>
                  <img src={Elastano} alt="" />  
                <p className='descripcion'>90% poliéster y 10% elastano</p>
                <p className='contenido'>
                Se observa la ergonómia y ajuste perfecto en tus pies, los hace sentir completamente liberados. La sensación de una tecnología tipo malla, hacen que se mantengan frescos, ligeros y ventilados, contaras con mayor higiene en tus pies.
                </p>
                </Fade>
                </Col>
                <Col md={4} sm={12} xl={4} className='texto'>
                <Fade right>
                <img src={CalcetaIcon} alt="" />
                <p className='descripcion'>El antideslizante</p>
                <p className='contenido'>
                Una fórmula de varios químicos harán que tus pies amarren perfectamente sobre la malla elástica o cualquier piso deslizante, el principal objetivo, evitar accidentes de cualquier tipo. Tendrás el mejor amarre y estabilidad al usarlos, los calcetines antiderrapantes Air Socks, te darán la mejor experiencia en tus saltos.
                </p>
                </Fade>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12} xl={6} className='texto_abajo'>
                <Fade bottom>
                <h5>Hacemos que tu negocio sea <u>más profesional</u>, ya que personalizamos tu marca, colocándola en el antideslizante o desde el tejido.

Agenda una cita con nosotros y comprueba, la <u>calidad</u> y <u>rapidez</u>  de nuestro servicio.</h5>
                </Fade>
                </Col>
                <Col md={6} sm={12} xl={6} className='calcetalogo'>
                    <Carousel > {/* fade */}
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Calceta2}
      alt="Second slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Calceta3}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Calceta4}
      alt="Third slide"
    />
  </Carousel.Item>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={Calceta5}
      alt="Third slide"
    />
  </Carousel.Item>
</Carousel>  
                </Col>
            </Row>
        </Container>
        </Container>
    )
}
