import React, {useState} from 'react';
import Logo from '../../assets/png/Logo.png';
import {Container} from 'react-bootstrap';
import Bounce from 'react-reveal/Bounce';
import { Link, animateScroll as scroll } from "react-scroll";
import './NavBar.scss';

export default function NavBar() {
    const [active, setActive] = useState("nav__menu");
    const [toggleIcon, setToggleIcon] = useState('nav__toggler');
    const navToggle =() =>{
        active === 'nav__menu' ? setActive('nav__menu nav__active') : setActive('nav__menu')

        toggleIcon === 'nav__toggler' ? setToggleIcon('nav__toggler toggle') : setToggleIcon('nav__toggler')
    }
    return (
        <>
            <nav className='nav'>
                <Container>
                <Bounce left>
                <a href="/">
                 <img src={Logo} alt="" className='nav__brand'/>
             </a>
             </Bounce>
             <ul className={active}>
             <Bounce right>
                <li className="nav__item"><Link  className="nav__link"
                activeClass="active"
                to="Home"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Home</Link></li>
                <li className="nav__item"><Link  className="nav__link"
                activeClass="active"
                to="Conocenos"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Conocenos</Link></li>
                <li className="nav__item"><Link className="nav__link"
                activeClass="active"
                to="Productos"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Productos</Link></li>
                <li className="nav__item"><Link className="nav__link"
                activeClass="active"
                to="Ubicacion"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Ubicacion</Link></li>
                <li className="nav__item"><Link className="nav__link"
                activeClass="active"
                to="Contacto"
                spy={true}
                smooth={true}
                offset={-70}
                duration= {500}
                >Contacto</Link></li>
                </Bounce>
             </ul>
             <div className={toggleIcon} onClick={navToggle}>
                 <div className="line1"></div>
                 <div className="line2"></div>
                 <div className="line3"></div>
             </div>     
                </Container>
            
            </nav>
        </>
    )
}
