import React from 'react';
import Whatsapp from "../../assets/png/whatsapp.png";
import Zoom from 'react-reveal/Zoom';
import './Button.scss';

export default function Button() {
    return (
        <Zoom>
        <div className='pulse'>
            
            <a href="https://wa.me/+525630566498?text=Me%20puedes%20brindar%20más%20información%20sobre%20tu%20producto%20por%20favor!">
             <img src={Whatsapp} alt="" />   
            </a>
         </div>
         </Zoom>
    )
}
